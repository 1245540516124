<template>
  <div class="ours">
    <Header />
    <div class="main">
      <img class="main-header" src="../assets/home/ours@2x.png" alt="">
<!--      <img class="line" src="../assets/home/line3@2x.png" alt="">-->
      <div class="main-text">
        <div class="type" :class="h5Language=='En'?'':'lt_17'">{{h5Language=='En'?'联系方式':'Contact Way'}}</div>
        <img class="line" src="../assets/home/line3@2x.png" alt="">
        <div class="adress" :class="h5Language=='En'?'':'lt_17'">{{h5Language=='En'?'地址：杭州市拱墅区环城北路139号杭州国际创意中心A座6楼':'Address：6th floor, Building A, HangzhouInternational Creative Center, No. 139 Huancheng North Road, Gongshu District, Hangzhou City'}}</div>
        <div class="code" :class="h5Language=='En'?'':'lt_17'">{{h5Language=='En'?'邮政编码：310018':'Company Postcode：310018'}}</div>
        <div class="code" :class="h5Language=='En'?'':'lt_17'">{{h5Language=='En'?'服务热线：0571-87212133':'For more information, call 0571-87212133'}}
        </div>
        <div class="code" :class="h5Language=='En'?'':'lt_17'">{{h5Language=='En'?'官网：www.xqctgroup.com':'Official website：www.xqctgroup.com'}}</div>
        <img class="qrcode" src="../assets/home/qrcode@2x.png" :class="h5Language=='En'?'':'mt_14'" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import Header from '../components/header'

export default {
  name: 'ours',
  components: {
    Header,
  },
  data(){
    return{
      h5Language:'En'
    }
  },
  mounted() {
    this.h5Language = localStorage.getItem('h5Language')||'En'
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.ours{
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  .main{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    background: #000000;
    .main-header{
      width: 100%;
      height: 175px;
      object-fit: cover;
    }
    .main-text{
      padding:5px 31px 0 36px;
      overflow: hidden;
    }
    .type{
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 24px;
      margin-bottom: 17px;
    }
    .line{
      width: 100%;
      height: 1px;
      margin-bottom: 11px;
    }
    .adress{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;

    }
    .code{
      margin: 7px 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;

    }
  }
  .qrcode{
    margin: 0 auto;
    display: block;
    width: 114px;
    height: 114px;
    margin-top: 38px;
  }
  .mt_14{
    margin-top: 14px;
  }
  .lt_17{
    line-height: 17px !important;
  }
}

</style>
